// import './App.css';
import About from './component/about';

function App() {
  return (
    <About />
  );
}

export default App;
